<template>
  <v-form @submit.prevent="submit" class="h-100 mb-3">
    <list :title="$_t('filters')" :filter="true" class="h-100">
      <hr class="mt-0" />
      <v-row>
        <v-col cols="12">
          <div class="h-35">
            <v-text-field
              :label="$_t('emailTitle')"
              class="no-error-msg"
              color="SonicSilver"
              outlined
              dense
              v-model="filter.name.val"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12">
          <div>
            <date-input
              :label="$_t('dateAfter')"
              v-model="filter.created_at.val1"
              color="SonicSilver"
              class="no-error-msg"
              outlined
              dense
            ></date-input>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="h-35">
            <date-input
              :label="$_t('dateBefore')"
              class="no-error-msg mt-n2"
              v-model="filter.created_at.val2"
              outlined
              dense
              color="SonicSilver"
            ></date-input>
          </div>
        </v-col>
      </v-row>
      <v-row class="my-3">
        <v-col cols="4" class="pr-0">
          <block-button
            height="30"
            class="w-100 cancel__button br__mode btn__size__14"
            :text="$_t('clear')"
            color="black"
            @click.native="clearFilter"
            outlined
            dense
          ></block-button>
        </v-col>
        <v-col cols="8 pl-1">
          <block-button
            height="30"
            class="w-100 site__button width-auto add__mode btn__size__18"
            type="submit"
            :text="$_t('execute')"
          ></block-button>
        </v-col>
      </v-row>
    </list>
  </v-form>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { cloneDeep } from "lodash";

const defaultFilter = {
  created_at: {
    type: "between",
    val1: null,
    val2: null,
    name: "created at",
  },
  name: {
    type: "like",
    val: null,
    name: "model name",
  },
  email: {
    type: "like",
    val: null,
    name: "email",
  },
  cell_phone: {
    type: "like",
    val: null,
    name: "cell phone",
  },
  instagram_id: {
    type: "like",
    val: null,
    name: "instagram id",
  },
  model_verified_at: {
    type: "in",
    val: null,
    name: "model verified at",
  },
  sort_by: {
    type: "in",
    val: null,
    name: "sort by",
  },
  language: {
    type: "in",
    val: null,
    name: "language",
    values: [],
    multiple: true,
  },
  language_level: {
    type: "in",
    val: null,
    name: "language level",
    values: [],
    multiple: true,
  },
  hair_color: {
    type: "in",
    val: null,
    name: "hair color",
  },
  eye_color: {
    type: "in",
    val: null,
    name: "eye color",
    values: [],
    multiple: true,
  },
  gender_id: {
    type: "in",
    val: null,
    name: "gender",
  },
  country_id: {
    type: "in",
    val: null,
    name: "country",
    values: [],
    multiple: true,
  },
  city: {
    type: "like",
    val: null,
    name: "city",
  },
  model_type: {
    type: "in",
    val: [],
    name: "model type",
    values: [],
    multiple: true,
  },
  birthday: {
    type: "between",
    val1: null,
    val2: null,
    name: "birthday",
  },
  height: {
    type: "between",
    val1: null,
    val2: null,
    name: "height",
  },
  waist: {
    type: "between",
    val1: null,
    val2: null,
    name: "waist",
  },
  bust: {
    type: "between",
    val1: null,
    val2: null,
    name: "bust",
  },
  hips: {
    type: "between",
    val1: null,
    val2: null,
    name: "hips",
  },
  shoe_size: {
    type: "between",
    val1: null,
    val2: null,
    name: "shoe size",
  },
  valid_until: {
    type: "between",
    val1: null,
    val2: null,
    name: "valid unit",
  },
};
export default {
  data: () => ({
    sorts: "created_at,desc",
    filter: cloneDeep(defaultFilter),
    verifies: [
      { name: "all", id: null },
      { name: "verified", id: "(ge)2000-01-01 00:00:00" },
      { name: "not verified", id: "(null)" },
    ],
    sortBy: [
      { name: "joined date", id: "created_at,desc" },
      { name: "height", id: "height,desc" },
      { name: "waist", id: "waist,desc" },
      { name: "hips", id: "hips,desc" },
    ],
  }),
  computed: {
    ...mapGetters("hairColor", ["getHairColors"]),
    ...mapGetters("eyeColor", ["getEyeColors"]),
    ...mapGetters("country", ["getCountries"]),
    ...mapGetters("modelType", ["getModelTypes"]),
    ...mapGetters("status", ["getStatuses"]),
    ...mapGetters("gender", ["getGenders"]),
    ...mapGetters("language", ["getLanguages"]),
    ...mapGetters("languageLevel", ["getLanguageLevels"]),
    valid_until: {
      get() {
        return this.value;
      },
      set(value) {
        if (value) {
          var expire = new Date();
          const today = new Date();
          this.filter.valid_until.val1 =
            today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
          expire.setDate(expire.getDate() + parseInt(value));
          expire = expire.toISOString().slice(0, 10);
        } else {
          expire = value;
        }
        this.filter.valid_until.val2 = expire;
        this.$emit("input", value);
      },
    },
    ageMin: {
      get() {
        return this.value;
      },
      set(value) {
        var date = new Date();
        date.setDate(date.getDate());
        date.setFullYear(date.getFullYear() - value);
        let finalDate =
          date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
        this.filter.birthday.val2 = finalDate;
      },
    },
    model_verified_at: {
      get() {
        return this.value ? this.value : this.verifies[0];
      },
      set(value) {
        this.filter.model_verified_at.val = value.id;
        this.filter.model_verified_at.value = value.name;
      },
    },
    ageMax: {
      get() {
        return this.value;
      },
      set(value) {
        var date = new Date();
        date.setDate(date.getDate());
        date.setFullYear(date.getFullYear() - value);
        let finalDate =
          date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
        this.filter.birthday.val1 = finalDate;
      },
    },
  },
  methods: {
    submit() {
      this.setFilterValues();
      this.setIsFiltered(true);
      this.SET_FILTER_MODEL(cloneDeep(this.filter));
      this.SET_SORT_MODEL(cloneDeep(this.sorts));
      this.loadModels();
    },
    setFilterValues() {
      this.filter.eye_color.values = this.getEyeColors;
      this.filter.model_type.values = this.getModelTypes;
      this.filter.country_id.values = this.getCountries;
      this.filter.language.values = this.getLanguages;
      (this.filter.language_level.values = this.getLanguageLevels),
        (this.filter.gender_id.values = this.getGenders);
    },
    ...mapMutations("model", ["SET_FILTER_MODEL", "SET_SORT_MODEL"]),
    ...mapActions("model", ["loadModels", "setIsFiltered"]),
    ...mapActions("hairColor", ["loadHairColors"]),
    ...mapActions("eyeColor", ["loadEyeColors"]),
    ...mapActions("country", ["loadCountries"]),
    ...mapActions("modelType", ["loadModelTypes"]),
    ...mapActions("status", ["loadStatuses"]),
    ...mapActions("gender", ["loadGenders"]),
    ...mapActions("language", ["loadLanguages"]),
    ...mapActions("languageLevel", ["loadLanguageLevels"]),
    clearFilter() {
      this.setIsFiltered(false);
      this.filter = cloneDeep(defaultFilter);
      this.SET_FILTER_MODEL(this.filter);
      this.SET_SORT_MODEL(this.sorts);
      this.loadModels();
    },
  },
  created() {
    this.loadHairColors();
    this.loadEyeColors();
    this.loadCountries();
    this.loadModelTypes();
    this.loadStatuses();
    this.loadGenders();
    this.loadLanguages();
    this.loadLanguageLevels();
  },
};
</script>
<style scoped>
.h-35 {
  height: 35px;
}
</style>
