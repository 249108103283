<template>
  <div class="wa__model__clients mb-1" @click="$_openModal('email_template')">
    <v-row class="text-left h-inherit ml-2 mr-2">
      <!--name  section-->
      <v-col cols="4" class="h-100">
        <div class="d-flex align-center h-100" style="overflow: hidden">
          <div class="mt-1 line-14">
            <div class="client__name wa__f__m__eb">sdfadsfadsfadsf</div>
            <small class="client__cell">232323232</small>
          </div>
        </div>
      </v-col>
      <v-col cols="4">
        <div class="d-flex align-center justify-center h-100">
          <div v-if="isBatch">
            <v-chip
              class="text-uppercase total__recipient wa__f__m__l mr-1"
              color="#ac377314"
              text-color="#AC3773"
              
              label
            >
              12 TOTAL recipients
            </v-chip>
          </div>
          <div class="line-14" v-else>
            <div class="client__recipient wa__f__m__l">sdfadsfadsfadsf</div>
            <v-chip
              class="client__recipient--chip wa__f__m__l mr-1"
              color="#e6e6e670"
              x-small
              text-color="black"
              label
            >
              miterpor@gmail.com
            </v-chip>
            <v-chip
              class="client__recipient--chip wa__f__m__l mr-1"
              color="#e6e6e670"
              x-small
              text-color="black"
              label
            >
              +1 47004700
            </v-chip>
          </div>
        </div>
      </v-col>
      <!-- control section-->
      <v-col cols="4" class="h-100">
        <div class="d-flex align-center h-100 justify-content-end">
          <circle-button
            icon="WMi-pencil"
            color="black"
            lg
            @click.stop.native="$_openModal('emailTemplate')"
          ></circle-button>
          <circle-button
            icon="WMi-trash"
            color="red"
            lg
            bg-color="bgLightPink"
            @click.stop.native="deleteMessage"
          ></circle-button>
        </div>
      </v-col>
      <!-- /control section-->
    </v-row>
  </div>
</template>
<script>
// import Name from "../Global/Section/Name.vue";
import { mapActions, mapGetters } from "vuex";
import toast from "@/utils/toast";
export default {
  data() {
    return {
      isBatch: true,
      details: {
        title: "CLIENT DETAILS",
        subTitle: "THE DETAILS YOU NEED",
      },
    };
  },
  components: {
    // Name,
  },
  props: {
    client: {
      type: Object,
    },
  },
  methods: {
    ...mapActions("client", [
      "deleteClient",
      "addToSelected",
      "removeFromSelected",
    ]),
    deleteMessage() {
      toast.question(this.$_trans("toast.del_client_item_msg"), this.$_trans("toast.del_client_item_title"), () => {
        this.deleteClient(this.client.id);
      });
    },
    changeClientSelected() {
      const index = this.getClientSelected.findIndex(
        (x) => x.id === this.client.id
      );
      if (index >= 0) {
        this.addToSelected(this.client);
      } else {
        this.removeFromSelected(this.client);
      }
    },
  },
  computed: {
    ...mapGetters("client", ["getClientSelected"]),
  },
};
</script>
<style scoped>
.wa__model__clients {
  border: 1px solid var(--color-light-gray);
  border-radius: 5px;
  width: 100%;
  height: 65px;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.client__name {
  font-family: "montserrat-light";
  font-size: 20px;
  text-transform: uppercase;
}
.client__cell {
  font-size: 10px;
  color: var(--color-smoky);
  font-family: "Montserrat-Light";
}
.client__recipient {
  font-size: 12px;
}
.client__recipient--chip {
  font-size: 8px;
}
.total__recipient {
  letter-spacing: 2px;
}
.h-inherit {
  height: inherit;
}
.line-14 {
  line-height: 14px;
}
</style>
