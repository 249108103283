<template >
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3">
        <v-row>
          <v-col cols="12">
            <site-header
              backRouteName="dashboard"
              :backText="$_t('backText')"
              :title="$_t('title')"
              :sub-title="$_t('subTitle')"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <main>
      <div>
        <v-row>
          <v-col cols="5" class="pb-0">
            <router-link 
              class="text-decoration-none"
              :to="{ name: 'emailTemplates', params: { locale: $_getlocaleParam() } }"
            >
              <v-card
                class="d-flex justify-center align-center card--rounded"
                color="#e6e6e670"
                elevation="0"
                height="73"
                rounded="10"
              >
                <v-icon large>WMi-doc-text-inv-1</v-icon>
                <div class="wa__tail__tail__main__title">
                  <div class="wa__tail__tail__main__title--title mt-2">
                    <div>Email Templates</div>
                  </div>
                  <div class="wa__tail__tail__main__title--subtitle">
                    <div>what we propose</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>
        </v-row>
        <v-row class="justify-content-center pb-4 wa__models">
          <v-col cols="12" md="2">
            <EmailManagmentFilter />
          </v-col>
          <v-col cols="12" md="10">
            <EmailManagmentItems />
          </v-col>
        </v-row>
      </div>
    </main>
    <footer>
      <div class="wa__home--footer">
        <site-footer :text="$_t('footerText')"></site-footer>
      </div>
    </footer>
    <!-- --------------------------------------------------------------------
                                Modals :: BEGIN
    ---------------------------------------------------------------------->
    <template>
      <div class="text-center"></div>
    </template>
    <!-- --------------------------------------------------------------------
                                Modals :: END
    ---------------------------------------------------------------------->
  </div>
</template>
<script>
import EmailManagmentItems from "@/components/EmailManagment/Items";
import EmailManagmentFilter from "@/components/EmailManagment/Filter";
export default {
  components: {
    EmailManagmentFilter,
    EmailManagmentItems,
  },
  computed: {},
};
</script>
<style scoped>
.wa__tail__tail__main__title {
  line-height: 16px;
}
.wa__tail__tail__main__title--title {
  font-family: "Barlow-EL";
  text-transform: uppercase;
  font-size: 25px;
  color: var(--color-black);
  font-weight: 600;
}
.wa__tail__tail__main__title--subtitle {
  font-size: 9px;
  font-family: "montserrat-light", sans-serif;
  color: var(--color-smoky);
  letter-spacing: 3px;
}
.card--rounded {
  border-radius: 14px;
}
</style>