<template>
  <list :title="$_t('emails')" class="wa__large">
    <hr class="mt-0" />
    <template>
      <v-tabs color="black" v-model="activeTab" centered slider-color="black">
        <v-tab>
          <div class="d-flex align-center">
            <div class="mb-2">
              <div class="teb__name batchs">BATCHS</div>
            </div>
          </div>
        </v-tab>
        <v-tab>
          <div class="d-flex align-center">
            <div class="mb-2">
              <div class="teb__name single">SINGLES</div>
            </div>
          </div>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <v-row class="d-none d-md-flex mt-3">
          <v-col cols="4" class="wa__model__title--title">
            <span class="pl-5">{{ $_t("itemsTitle") }}</span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="4" class="wa__model__title--title text-left pl-10">
            <span class="pl-16">{{ $_t("recipient") }}</span>
          </v-col>
          <v-col cols="4" class="wa__model__title--title text-right">
            <span class="pr-10">{{ $_t("controls") }}</span>
          </v-col>
        </v-row>
        <v-tab-item>
          <data-iterator
            :items="getReviews"
            @pagination="$emit('pagination', $event)"
            :pagination="getPaginationModel"
          >
            <template #loading>
              <we-loading />
            </template>
            <template #no-items>
              <we-no-items
                :src="$_staticPath('images/global/animated/clients.gif')"
                route-name="AddClient"
                :textFa="
                  $_name(
                    'crm.client.name',
                    'هنوز هیچ $_name ای تعریف نکرده اید!'
                  )
                "
                textEn=" No Client Yet! "
                themeColor="client"
                :btnText="$_name('crm.client.name', 'ثبت اولین $_name ')"
                btnIcon="plus"
              />
            </template>
            <template #items="{ itemNumber, item }">
              <TemplateItem
                :item-number="itemNumber"
                :is-lastest="itemNumber === getReviews.length"
                :review="item"
              ></TemplateItem>
            </template>
          </data-iterator>
        </v-tab-item>
        <v-tab-item>
          <data-iterator
            :items="getReviews"
            @pagination="$emit('pagination', $event)"
            :pagination="getPaginationModel"
          >
            <template #loading>
              <we-loading />
            </template>
            <template #no-items>
              <we-no-items
                :src="$_staticPath('images/global/animated/clients.gif')"
                route-name="AddClient"
                :textFa="
                  $_name(
                    'crm.client.name',
                    'هنوز هیچ $_name ای تعریف نکرده اید!'
                  )
                "
                textEn=" No Client Yet! "
                themeColor="client"
                :btnText="$_name('crm.client.name', 'ثبت اولین $_name ')"
                btnIcon="plus"
              />
            </template>
            <template #items="{ itemNumber, item }">
              <TemplateItem
                :item-number="itemNumber"
                :is-lastest="itemNumber === getReviews.length"
                :review="item"
              ></TemplateItem>
            </template>
          </data-iterator>
        </v-tab-item>
      </v-tabs-items>
      <!-- --------------------------------------------------------------------
                            Modals :: BEGIN
        ---------------------------------------------------------------------->
      <template>
        <div class="text-center">
          <EmailTemplateModal />
          <EmailDetailModal />
        </div>
      </template>
      <!-- --------------------------------------------------------------------
                                    Modals :: END
        ---------------------------------------------------------------------->
    </template>
  </list>
</template>

<script>
import TemplateItem from "./Item.vue";
import EmailTemplateModal from "@/components/EmailTemplates/modals/AddEmailTemplate.vue";
import EmailDetailModal from "@/components/EmailTemplates/modals/EmailDetail.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  components: {
    EmailTemplateModal,
    TemplateItem,
    EmailDetailModal,
  },
  data() {
    return {
      getReviews: [
        {
          user: {
            name: "علیرضا حسنی",
            created_at: "2021-11-28 17:08:58",
            score: 4,
          },
          Strengths: [
            {
              id: 1,
              text: "بسیار آیتم عالی هست",
            },
            {
              id: 2,
              text: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ",
            },
            {
              id: 3,
              text: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ",
            },
          ],
          weakPoints: [
            {
              id: 1,
              text: "بسیار آیتم عالی هست",
            },
            {
              id: 2,
              text: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ",
            },
            {
              id: 3,
              text: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ",
            },
          ],
          description:
            "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.",
        },
      ],
      activeTab: null,
    };
  },
  computed: {
    ...mapGetters("model", [
      "getModels",
      "getPaginationModel",
      "getFiltersModel",
      "isFilteredModel",
      "getModelLoading",
    ]),
    ...mapGetters("modal", ["isModal"]),
  },
  methods: {
    ...mapActions("model", ["loadModels", "toggleSelectAll"]),
    ...mapMutations("model", ["SET_PAGINATION"]),
    changePagination(page) {
      if (this.getPaginationModel.page !== page) {
        this.SET_PAGINATION({
          pagination: page,
        });
        this.loadModels();
      }
    },
  },
  created() {
    this.changePagination(1)
  },
};
</script>

<style scoped>
.wa__model__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  height: 16px;
}

.wa__model__title--title {
  font-family: "Montserrat";
  font-size: 12px;
  color: var(--color-light-gray);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.v-tab .teb__name {
  font-size: 20px;
  color: #000;
  font-family: "Montserrat-light";
}

.add__new__template {
  font-size: 18px !important;
}
</style>
<style>
.theme--light.v-tabs > .v-tabs-bar {
  background-color: rgba(255, 1, 1, 0) !important;
  border-bottom: 1px solid#E6E6E6;
}
.v-tab:not(.v-tab--active) div div .single,
.v-tab:not(.v-tab--active) div div .batchs {
  color: #c3c3c3 !important;
}
</style>
