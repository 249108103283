var render = function render(){var _vm=this,_c=_vm._self._c;return _c('list',{staticClass:"wa__large",attrs:{"title":_vm.$_t('emails')}},[_c('hr',{staticClass:"mt-0"}),[_c('v-tabs',{attrs:{"color":"black","centered":"","slider-color":"black"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab',[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"teb__name batchs"},[_vm._v("BATCHS")])])])]),_c('v-tab',[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"teb__name single"},[_vm._v("SINGLES")])])])])],1),_c('v-tabs-items',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-row',{staticClass:"d-none d-md-flex mt-3"},[_c('v-col',{staticClass:"wa__model__title--title",attrs:{"cols":"4"}},[_c('span',{staticClass:"pl-5"},[_vm._v(_vm._s(_vm.$_t("itemsTitle")))])]),_c('v-spacer'),_c('v-col',{staticClass:"wa__model__title--title text-left pl-10",attrs:{"cols":"4"}},[_c('span',{staticClass:"pl-16"},[_vm._v(_vm._s(_vm.$_t("recipient")))])]),_c('v-col',{staticClass:"wa__model__title--title text-right",attrs:{"cols":"4"}},[_c('span',{staticClass:"pr-10"},[_vm._v(_vm._s(_vm.$_t("controls")))])])],1),_c('v-tab-item',[_c('data-iterator',{attrs:{"items":_vm.getReviews,"pagination":_vm.getPaginationModel},on:{"pagination":function($event){return _vm.$emit('pagination', $event)}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('we-loading')]},proxy:true},{key:"no-items",fn:function(){return [_c('we-no-items',{attrs:{"src":_vm.$_staticPath('images/global/animated/clients.gif'),"route-name":"AddClient","textFa":_vm.$_name(
                  'crm.client.name',
                  'هنوز هیچ $_name ای تعریف نکرده اید!'
                ),"textEn":" No Client Yet! ","themeColor":"client","btnText":_vm.$_name('crm.client.name', 'ثبت اولین $_name '),"btnIcon":"plus"}})]},proxy:true},{key:"items",fn:function({ itemNumber, item }){return [_c('TemplateItem',{attrs:{"item-number":itemNumber,"is-lastest":itemNumber === _vm.getReviews.length,"review":item}})]}}])})],1),_c('v-tab-item',[_c('data-iterator',{attrs:{"items":_vm.getReviews,"pagination":_vm.getPaginationModel},on:{"pagination":function($event){return _vm.$emit('pagination', $event)}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('we-loading')]},proxy:true},{key:"no-items",fn:function(){return [_c('we-no-items',{attrs:{"src":_vm.$_staticPath('images/global/animated/clients.gif'),"route-name":"AddClient","textFa":_vm.$_name(
                  'crm.client.name',
                  'هنوز هیچ $_name ای تعریف نکرده اید!'
                ),"textEn":" No Client Yet! ","themeColor":"client","btnText":_vm.$_name('crm.client.name', 'ثبت اولین $_name '),"btnIcon":"plus"}})]},proxy:true},{key:"items",fn:function({ itemNumber, item }){return [_c('TemplateItem',{attrs:{"item-number":itemNumber,"is-lastest":itemNumber === _vm.getReviews.length,"review":item}})]}}])})],1)],1),[_c('div',{staticClass:"text-center"},[_c('EmailTemplateModal'),_c('EmailDetailModal')],1)]]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }